import { useState } from 'react';
// @mui
import { Box, Card, Button, Typography, Stack } from '@mui/material';

import { ModalTableUpdate, ModalTableDelete } from './index';

// ----------------------------------------------------------------------


export default function TableCard({ table, noEdit }) {
  const { name, description, fields, color } = table;

  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const backgroundColorCard = color ?? '#F7C8EE';

  return (
    <Card>
      <Box sx={{ pt: '30%', position: 'relative' }}>
        <Box sx={{
          top: 0,
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          position: 'absolute',
          backgroundColor: backgroundColorCard,
          color: 'white',
          textAlign: 'center',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontWeight: 'bold',
          textShadow: '1px 1px 2px rgba(0, 0, 0, 0.3)',
        }}>
          {name}
        </Box>
      </Box>

      <Stack spacing={2} sx={{ p: 2 }}>
        {description}

        <Stack sx={{ paddingTop: 2 }} direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="subtitle2">
            {fields}
          </Typography>
        </Stack>
      </Stack>

      {noEdit === false && <Stack direction={"row"} spacing={2} sx={{ p: 2 }}>
        <Button sx={{ flex: 1 }} variant="text" onClick={() => { setOpenModal(true) }}>Edit </Button>
        <Button sx={{ flex: 1, color: 'red' }} variant="text" onClick={() => { setOpenDeleteModal(true) }}>Delete </Button>
      </Stack>}

      <ModalTableUpdate tableData={table} open={openModal} onClose={() => { setOpenModal(false) }} onSubmit={() => { }} />
      <ModalTableDelete tableData={table} open={openDeleteModal} onClose={() => { setOpenDeleteModal(false) }} onSubmit={() => { }} />
    </Card>
  );
}
