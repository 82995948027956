// src/ChatGPT.js
import React, { useState } from 'react';
import {Configuration, OpenAIApi } from 'openai';

const apiKey = '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'; // Replace with your OpenAI API key

const configuration = new Configuration({
    apiKey: "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",
  });

const openai = new OpenAIApi(configuration);

const ChatGPT = () => {
  const [userInput, setUserInput] = useState('');
  const [response, setResponse] = useState('');

  const handleUserInput = (e) => {
    setUserInput(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await openai.createCompletion({
        model: 'text-davinci-003', // Replace with the desired GPT-3 model
        prompt: userInput,
        max_tokens: 50,
        temperature: 0.7,
        n: 1,
      });

      console.log(response);

      const generatedText = response.data.choices[0].text.trim();
      setResponse(generatedText);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <input type="text" value={userInput} onChange={handleUserInput} />
        <button type="submit">Send</button>
      </form>
      {response && <h4>{response}</h4>}
    </div>
  );
};

export default ChatGPT;