// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  // {
  //   title: 'dashboard',
  //   path: '/dashboard/app',
  //   icon: icon('ic_analytics'),
  // },
  {
    title: 'tables',
    path: '/dashboard/tables',
    icon: icon('ic_user'),
  },
  {
    title: 'Create Query',
    path: '/dashboard/makeQuery',
    icon: icon('ic_cart'),
  },
  {
    title: 'Create Mutation',
    path: '/dashboard/makeMutation',
    icon: icon('ic_blog'),
  },
  {
    title: 'Create Formula',
    path: '/dashboard/makeSpreadsheetQuery',
    icon: icon('ic_sheets'),
  },
  {
    title: 'React component',
    path:  '/dashboard/react_component_creation',
    icon: icon('ic_react'),
  },
  {
    title: 'history',
    path:  '/dashboard/history',
    icon: icon('ic_lock'),
  },
  // {
  //   title: 'blog',
  //   path: '/dashboard/blog',
  //   icon: icon('ic_blog'),
  // }, 
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: icon('ic_disabled'),
  // },
];

export default navConfig;
