import PropTypes from 'prop-types';
// @mui
import { Grid } from '@mui/material';
import TableCard from './TableCard';

// ----------------------------------------------------------------------

TableList.propTypes = {
  tables: PropTypes.array.isRequired,
};

export default function TableList({ tables, noEdit, ...other }) {
  return (
    <Grid container spacing={3} {...other}>
      {tables.map((product) => (
        <Grid key={product.id} item xs={12} sm={6} md={3}>
          <TableCard noEdit={noEdit} table={product} />
        </Grid>
      ))}
    </Grid>
  );
}
