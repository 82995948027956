// src/Tables.js
import React, { useState, useEffect } from 'react';
import { auth, firestore } from './firebaseConfig';

const Tables = () => {
  const [tableName, setTableName] = useState('');
  const [tableDescription, setTableDescription] = useState('');
  const [tableFields, setTableFields] = useState('');
  const [tables, setTables] = useState([]);

  const user = auth.currentUser;
  const userTablesRef = firestore.collection('users').doc(user?.uid ?? "test").collection('tables');

  const handleTableNameChange = (e) => {
    setTableName(e.target.value);
  };

  const handleTableDescriptionChange = (e) => {
    setTableDescription(e.target.value);
  };

  const handleTableFieldsChange = (e) => {
    setTableFields(e.target.value);
  };

  const handleAddTable = (e) => {
    e.preventDefault();

    const newTable = {
      name: tableName,
      description: tableDescription,
      fields: tableFields,
    };

    userTablesRef.add(newTable)
      .then(() => {
        setTableName('');
        setTableDescription('');
        setTableFields('');
      })
      .catch((error) => {
        console.log('Error adding table: ', error);
      });
  };

  useEffect(() => {
    const unsubscribe = userTablesRef.onSnapshot((snapshot) => {
      const tableData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setTables(tableData);
    });

    return () => {
      unsubscribe();
    };
  }, [userTablesRef]);

  return (
    <div>
      <h2>Tables</h2>

      <form onSubmit={handleAddTable}>
        <label>
          Table Name:
          <input type="text" value={tableName} onChange={handleTableNameChange} />
        </label>
        <br />
        <label>
          Table Description:
          <input type="text" value={tableDescription} onChange={handleTableDescriptionChange} />
        </label>
        <br />
        <label>
          Table Fields:
          <input type="text" value={tableFields} onChange={handleTableFieldsChange} />
        </label>
        <br />
        <button type="submit">Add Table</button>
      </form>

      <h3>Table List</h3>
      <ul>
        {tables.map((table) => (
          <li key={table.id} style={{marginBottom: 12}}>
            <strong>Name:</strong> {table.name}<br />
            <strong>Description:</strong> {table.description}<br />
            <strong>Fields:</strong> {table.fields}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Tables;