import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
import { faker } from '@faker-js/faker';

import moment from 'moment';

// @mui
import { Container, Typography, Grid } from '@mui/material';

//firebase
import { auth, firestore } from '../firebaseConfig';

import {
  HistorySection,
} from '../sections/@dashboard/app';


// ----------------------------------------------------------------------

export default function HistoryPage() {

  ///// FIREBASE
  const [history, setHistory] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);

  const renderTimestamp = (createdAt) => {
    if (createdAt === null || createdAt === undefined) {
      return "No date";
    }

    console.log(createdAt);

    const timestamp = moment(createdAt);

    return timestamp.format('MMM Do YYYY h:mm a');
  };

  useEffect(() => {
    const user = auth.currentUser;
    setCurrentUser(user);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.currentUser]);


  const userHistoryRef = firestore
    .collection("users")
    .doc(currentUser?.uid ?? "test")
    .collection("history").orderBy("createdAt", "desc").limit(50);


  const fetchUserHistory = async () => {
    try {
      const snapshot = await userHistoryRef.get();
      const historyData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      console.log(historyData);
      setHistory(historyData);
    } catch (error) {
      console.log("Error fetching user tables: ", error);
    }
  };

  useEffect(() => {
    fetchUserHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  return (
    <>
      <Helmet>
        <title> Dashboard: Tables | SpeakDB </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          History
        </Typography>

        <Grid xs={12} md={6} lg={8}>
          <HistorySection
            title="Previous Queries and Mutations"
            list={history.map((record, index) => ({
              id: index,
              title: record?.promp,
              description: record.response,
              postedAt: renderTimestamp(record?.createdAt?.toDate() ?? null),
            }))}
          />
        </Grid>
      </Container>
    </>
  );
}
