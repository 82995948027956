import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import BlogPage from './pages/BlogPage';
//import UserPage from './pages/UserPage';
import LoginPage from './pages/LoginPage';
import SignupPage from './pages/SignupPage';
import LandingPage from './pages/LandingPage';
import Page404 from './pages/Page404';
// import ProductsPage from './pages/ProductsPage';
// import DashboardAppPage from './pages/DashboardAppPage';

import TablesPage from './pages/TablesPage';


import Dashboard from './Dashboard';
import Tables from './Tables';

import MakeQueryPage from './pages/MakeQueryPage';
import HistoryPage from './pages/HistoryPage';
import MakeReactComponentPage from './pages/MakeReactComponentPage';
import MakeMutationPage from './pages/MakeMutationPage';

import MakeCSVQueryPage from './pages/MakeCSVQueryPage';
// import MakeSpreadsheetPage from './pages/MakeSpreadsheetPage';
import MakeSpreadsheetsQueryPage from './pages/MakeSpreadSheetsQueryPage';
import FreeAccountOverPage from './pages/FreeAccountOverPage';



// ----------------------------------------------------------------------

export default function NewRouter() {
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <TablesPage /> },
        { path: 'tables', element: <TablesPage /> },
        { path: 'makeQuery', element: <MakeQueryPage /> },
        { path: 'makeMutation', element: <MakeMutationPage /> },
        { path: 'makeSpreadsheetQuery', element: <MakeSpreadsheetsQueryPage /> },
        { path: 'blog', element: <BlogPage /> },
        { path: 'history', element: <HistoryPage /> },
        { path: 'react_component_creation', element: <MakeReactComponentPage /> },
        { path: 'fremium_over', element: <FreeAccountOverPage /> },
      ],
    },
    {
      path: 'new_login',
      element: <LoginPage />,
    },
    {
      path: 'new_signup',
      element: <SignupPage />,
    },
    {
      path: 'landing',
      element: <LandingPage />,
    },
    // {
    //   path: 'csv_query',
    //   element: <MakeCSVQueryPage />,
    // },
    // {
    //   path: 'google_spreadsheet',
    //   element: <MakeSpreadsheetPage />,
    // },
    {
      path: 'tables',
      element: <Tables />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
