import React, { useState } from 'react';

import { Button, Modal, Box, TextField } from '@mui/material';

import { firestore, auth } from '../../../firebaseConfig';

const ModalTableUpdate = ({ tableData, open, onClose, onSubmit }) => {
    const user = auth.currentUser;
    const [tableName, setTableName] = useState(tableData.name ?? '');
    const [tableDescription, setTableDescription] = useState(tableData.description ?? '');
    const [tableFields, setTableFields] = useState(tableData.fields ?? '');

    const handleTableNameChange = (e) => {
        setTableName(e.target.value);
    };

    const handleTableDescriptionChange = (e) => {
        setTableDescription(e.target.value);
    };

    const handleTableFieldsChange = (e) => {
        setTableFields(e.target.value);
    };

    const handleTableUpdate = async (e) => {
        console.log(tableData);
        if (!user?.uid){
            return;
        }
        console.log("aaaaahh");
        e.preventDefault();

        try {
            const tableRef = firestore.collection('users').doc(user.uid).collection('tables').doc(tableData.id);

            await tableRef.update({
                name: tableName,
                description: tableDescription,
                fields: tableFields,
            });

            console.log('Table updated successfully!');
            onClose();
            window.location.reload();
        } catch (error) {
            console.log('Error updating table: ', error);
        }
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 4,
                }}
            >
                <form onSubmit={handleTableUpdate}>
                    <TextField
                        label="Table Name"
                        value={tableName}
                        onChange={handleTableNameChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Table Description"
                        value={tableDescription}
                        onChange={handleTableDescriptionChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Table Fields"
                        value={tableFields}
                        onChange={handleTableFieldsChange}
                        fullWidth
                        margin="normal"
                    />
                    <Button variant="contained" type="submit">
                        Update Table
                    </Button>
                </form>
            </Box>
        </Modal>
    );
};

export default ModalTableUpdate;