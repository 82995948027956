import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';

// @mui
import { Container, MenuItem, Typography, Box, TextField, Button, Snackbar, CircularProgress } from '@mui/material';
// components
import { TableList } from '../sections/@dashboard/tables';

import { useNavigate } from 'react-router-dom';
import { useOutletContext } from "react-router-dom";

//firebase
import { auth, firestore, firebaseRef } from '../firebaseConfig';


import { Configuration, OpenAIApi } from "openai";

const configuration = new Configuration({
    apiKey: "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",
});


const openai = new OpenAIApi(configuration);


const valuesOptions = [
    {
        value: 'PostgreSQL',
        label: 'PostgreSQL',
    },
    {
        value: 'MySQL',
        label: 'MySQL',
    },
    {
        value: 'Rails console',
        label: 'Rails console',
    },
    {
        value: 'Laravel Query Builder',
        label: 'Laravel Query Builder',
    },
    {
        value: 'Sqlalchemy Query Builder',
        label: 'Sqlalchemy Query Builder',
    },
];


// ----------------------------------------------------------------------

export default function MakeMutationPage() {

    /////// FREEMIUM
    const navigate = useNavigate();
    const [historyCount] = useOutletContext();
    if (historyCount >= 50) {
        navigate('/dashboard/fremium_over');
    }
    /////// FREEMIUM

    ///// FIREBASE
    const [textInput, setTextInput] = useState("");
    const [selectedOption, setSelectedOption] = useState("");
    const [response, setResponse] = useState("");
    const [submitting, setSubmitting] = useState(false);
    const [tables, setTables] = useState([]);
    const [openSnackBar, setOpenSnackBar] = useState(false);

    const [tableOptions, setTableOptions] = useState([]);
    const [selectedTables, setSelectedTables] = useState([]);
    const [currentUser, setCurrentUser] = useState(null);

    useEffect(() => {
        const user = auth.currentUser;
        setCurrentUser(user);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth.currentUser]);


    const userTablesRef = firestore
        .collection("users")
        .doc(currentUser?.uid ?? "test")
        .collection("tables");


    const userHistoryRef = firestore
        .collection("users")
        .doc(currentUser?.uid ?? "test")
        .collection("history");


    const fetchUserTables = async () => {
        try {
            const snapshot = await userTablesRef.get();
            const tableData = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            //console.log(tableData);
            setTables(tableData);

            const tableOptions = tableData.map(obj => {
                return {
                    label: obj.name,
                    value: obj,
                }
            })

            setTableOptions(tableOptions);

        } catch (error) {
            console.log("Error fetching user tables: ", error);
        }
    };

    useEffect(() => {
        fetchUserTables();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUser]);


    const handleTextInputChange = (e) => {
        setTextInput(e.target.value);
    };

    const handleOptionChange = (e) => {
        setSelectedOption(e.target.value);
    };

    const handleTablesSelectorChange = (event, values) => {
        setSelectedTables(values);
    };

    const handleQuerySubmit = async (e) => {

        // SELECT TABLES
        //const tablesToChatGPT = selectedTables.map(obj => obj.value);
        // const tableStructure = tablesToChatGPT
        //   .map(
        //     (table) =>
        //       `Table name: ${table.name}\nTable Description: ${table.description}\nTable fields: ${table.fields}\n`
        //   )
        //   .join("\n");

        const prompt = `I have the following database structure:\n\n`;

        const tableStructure = tables
            .map(
                (table) =>
                    `Table name: ${table.name}\nTable Description: ${table.description}\nTable fields: ${table.fields}\n`
            )
            .join("\n");

        const fullPrompt = `${prompt}${tableStructure}\n Create code to query:\n\n${textInput} , return the code in a ${selectedOption} structure. Your response will be parsed programmatically so please only return the code with no explanation. Only the code.`;

        try {
            setSubmitting(true);

            const completion = await openai.createChatCompletion({
                model: "gpt-3.5-turbo-16k",
                messages: [{ "role": "user", "content": fullPrompt }],
            })

            // const response = await openai.createCompletion({
            //   model: "text-davinci-003", // Replace with the desired GPT-3 model
            //   prompt: fullPrompt,
            //   max_tokens: 500,
            //   temperature: 0.7,
            //   n: 1,
            // });


            //const generatedText = response.data.choices[0].text;
            const generatedText = completion.data.choices[0]['message']['content'];

            /////console.log(generatedText);

            //const updatedGeneratedText = generatedText;
            const updatedGeneratedText = generatedText.replace(/^\n+/, '');

            setSubmitting(false);

            setResponse(updatedGeneratedText);

            const newHistoryRecord = {
                promp: textInput,
                selector: selectedOption,
                response: generatedText,
                createdAt: firebaseRef.firestore.FieldValue.serverTimestamp(),
            };

            userHistoryRef.add(newHistoryRecord)
                .then(() => {
                    console.log("done");
                })
                .catch((error) => {
                    console.log('Error adding table: ', error);
                });

        } catch (error) {
            console.log("Error generating response: ", error);
        }
    };

    const handleSnackBarClick = () => {
        setOpenSnackBar(true);
    };

    const handleSnackBarClose = () => {
        setOpenSnackBar(false);
    };

    return (
        <>
            <Helmet>
                <title> Dashboard: Make Mutation | SpeakDB </title>
            </Helmet>

            <Container>
                <Typography variant="h4" sx={{ mb: 5 }}>
                    Create Mutation
                </Typography>

                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { m: 1, width: '98%' },
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <div>
                        <TextField
                            id="make-query"
                            label="Explain your mutation... add new records to your tables..."
                            multiline
                            rows={4}
                            onChange={handleTextInputChange}
                        />

                        <TextField
                            id="outlined-select-currency"
                            select
                            label="Select"
                            helperText="Please select mutation type"
                            onChange={handleOptionChange}
                        >
                            {valuesOptions.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>

                        {/* <Autocomplete
              multiple
              options={tableOptions}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField {...params} label="Select Tables" variant="outlined" />
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <span
                    key={option.value}
                    {...getTagProps({ index })}
                    style={{ backgroundColor: '#2065D1', color: '#fff', padding: '5px', borderRadius: '5px' }}
                  >
                    {option.label}
                  </span>
                ))
              }
              onChange={handleTablesSelectorChange}
            /> */}


                        <Button onClick={() => { handleQuerySubmit() }} sx={{ width: '25ch', height: 56, mt: 1, ml: 1 }} variant="contained">
                            Create Mutation
                        </Button>

                        {submitting && <Box sx={{ mt: 1, ml: 1 }}>
                            <CircularProgress />
                        </Box>}
                    </div>
                </Box>

                {response && response !== "" && <div style={{ paddingTop: '28px', marginLeft: 8 }}>
                    <code style={{ fontFamily: 'monospace', backgroundColor: '#f1f1f1', padding: 4, whiteSpace: 'pre', fontSize: 16, marginRight: 12 }} className="code">
                        {response}
                    </code>

                    <Button variant="text" onClick={() => {
                        navigator.clipboard.writeText(response);
                        handleSnackBarClick();
                    }}>Copy code</Button>
                </div>}


                <TableList noEdit={true} sx={{ mt: 1 }} tables={tables ?? []} />
            </Container>

            <Snackbar
                open={openSnackBar}
                onClose={handleSnackBarClose}
                autoHideDuration={3000}
                message="Code copied"
            />
        </>
    );
}
