// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './Login';
import SignUp from './SignUp';
import Dashboard from './Dashboard';
import Tables from './Tables';
import MakeQuery from './MakeQuery';

import LoginPage from './pages/LoginPage';
import SignupPage from './pages/SignupPage';

// routes
import NewRouter from './routes';


import { HelmetProvider } from 'react-helmet-async';

// theme
import ThemeProvider from './theme';

// components
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';

const App = () => {
  return (
    <HelmetProvider>
      <Router>
        <ThemeProvider>
          <ScrollToTop />
          <StyledChart />
          {/* <Routes>
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/new_login" element={<LoginPage />} />
            <Route exact path="/new_signup" element={<SignupPage />} />
            <Route exact path="/signup" element={<SignUp />} />
            <Route exact path="/dashboard" element={<Dashboard />} />
            <Route exact path="/makeQuery" element={<MakeQuery />} />
            <Route exact path="/tables" element={<Tables />} />
            <Route exact path="/" element={<Dashboard />} />
          </Routes> */}
          <NewRouter />
        </ThemeProvider>
      </Router>
    </HelmetProvider>
  );
};

export default App;




