import React, { useEffect } from 'react';


// BOOTSTRAP
import 'bootstrap/dist/css/bootstrap.min.css';
import { Accordion, AccordionContext, AccordionCollapse, AccordionButton, Alert, Anchor, Badge, Breadcrumb, BreadcrumbItem, Button, ButtonGroup, ButtonToolbar, Card, CardImg, CardGroup, Carousel, CarouselItem, CloseButton, Col, Collapse, Dropdown, DropdownButton, Fade, Form, FormControl, FormCheck, FormFloating, FloatingLabel, FormGroup, FormLabel, FormText, FormSelect, Container, Image, Figure, InputGroup, ListGroup, ListGroupItem, Modal, ModalBody, ModalDialog, ModalHeader, ModalFooter, ModalTitle, Nav, Navbar, NavbarBrand, NavDropdown, NavItem, NavLink, Offcanvas, OffcanvasHeader, OffcanvasTitle, OffcanvasBody, Overlay, OverlayTrigger, PageItem, Pagination, Placeholder, PlaceholderButton, Popover, PopoverHeader, PopoverBody, ProgressBar, Ratio, Row, Spinner, SplitButton, SSRProvider, Stack, Tab, TabContainer, TabContent, Table, TabPane, Tabs, ThemeProvider, Toast, ToastBody, ToastHeader, ToastContainer, ToggleButton, ToggleButtonGroup, Tooltip } from 'react-bootstrap';


import JsxParser from 'react-jsx-parser';

const ReactComponentBootstrapSample = ({ text }) => {

    useEffect(() => {
        // Create a script Element for React
        const reactScript = document.createElement('script');
        reactScript.src = 'https://cdn.jsdelivr.net/npm/react/umd/react.production.min.js';
        reactScript.crossOrigin = true;

        // Append the React script to the document's head
        document.head.appendChild(reactScript);

        // Optionally, add a listener to handle React script loading completion if needed
        reactScript.onload = () => {
            console.log('React script has been loaded!');
            // Perform any additional setup related to the script if required
        };

        // Create a script element for ReactDOM
        const reactDOMScript = document.createElement('script');
        reactDOMScript.src = 'https://cdn.jsdelivr.net/npm/react-dom/umd/react-dom.production.min.js';
        reactDOMScript.crossOrigin = true;

        // Append the ReactDOM script to the document's head
        document.head.appendChild(reactDOMScript);

        // Optionally, add a listener to handle ReactDOM script loading completion if needed
        reactDOMScript.onload = () => {
            console.log('ReactDOM script has been loaded!');
            // Perform any additional setup related to the script if required
        };

        // Create a script element for React Bootstrap
        const reactBootstrapScript = document.createElement('script');
        reactBootstrapScript.src = 'https://cdn.jsdelivr.net/npm/react-bootstrap@next/dist/react-bootstrap.min.js';
        reactBootstrapScript.crossOrigin = true;

        // Append the React Bootstrap script to the document's head
        document.head.appendChild(reactBootstrapScript);

        // Optionally, add a listener to handle React Bootstrap script loading completion if needed
        reactBootstrapScript.onload = () => {
            console.log('React Bootstrap script has been loaded!');
            // Perform any additional setup related to the script if required
        };

        // Clean up the scripts when the component is unmounted
        return () => {
            if (reactScript.parentNode) {
                reactScript.parentNode.removeChild(reactScript);
            }
            if (reactDOMScript.parentNode) {
                reactDOMScript.parentNode.removeChild(reactDOMScript);
            }
            if (reactBootstrapScript.parentNode) {
                reactBootstrapScript.parentNode.removeChild(reactBootstrapScript);
            }
        };
    }, []);

    return (
        <div style={{ marginTop: 24 }}>
            {/* <div dangerouslySetInnerHTML={{ __html: text }} /> */}

            <JsxParser
                components={{ Accordion, AccordionContext, AccordionCollapse, AccordionButton, Alert, Anchor, Badge, Breadcrumb, BreadcrumbItem, Button, ButtonGroup, ButtonToolbar, Card, CardImg, CardGroup, Carousel, CarouselItem, CloseButton, Col, Collapse, Dropdown, DropdownButton, Fade, Form, FormControl, FormCheck, FormFloating, FloatingLabel, FormGroup, FormLabel, FormText, FormSelect, Container, Image, Figure, InputGroup, ListGroup, ListGroupItem, Modal, ModalBody, ModalDialog, ModalHeader, ModalFooter, ModalTitle, Nav, Navbar, NavbarBrand, NavDropdown, NavItem, NavLink, Offcanvas, OffcanvasHeader, OffcanvasTitle, OffcanvasBody, Overlay, OverlayTrigger, PageItem, Pagination, Placeholder, PlaceholderButton, Popover, PopoverHeader, PopoverBody, ProgressBar, Ratio, Row, Spinner, SplitButton, SSRProvider, Stack, Tab, TabContainer, TabContent, Table, TabPane, Tabs, ThemeProvider, Toast, ToastBody, ToastHeader, ToastContainer, ToggleButton, ToggleButtonGroup, Tooltip }}
                jsx={text}
            />
        </div>
    );
};

export default ReactComponentBootstrapSample;



// `<Card style={{ width: '18rem' }}>
//         <Card.Body>
//           <Card.Title>Card Title</Card.Title>
//           <Card.Text>
//             Some quick example text to build on the card title and make up the
//             bulk of the card's content.
//           </Card.Text>
//         </Card.Body>
//       </Card>`