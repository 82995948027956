import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';

import ReactPlaygroundStyled from 'react-playground-styled';
import styled from 'styled-components';

import axios from 'axios';

// @mui

import {
  Accordion, AccordionActions, AccordionDetails, AccordionSummary, Alert, AlertTitle, AppBar, Autocomplete, Avatar, AvatarGroup, Backdrop, Badge, BottomNavigation, BottomNavigationAction, Box, Breadcrumbs, Button, ButtonBase, ButtonGroup, Card, CardActionArea, CardActions, CardContent, CardHeader, CardMedia, Checkbox, Chip, CircularProgress, ClickAwayListener, Collapse, Container, CssBaseline, darkScrollbar, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Drawer, Fab, Fade, FilledInput, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Grid, Unstable_Grid2, Grow, Hidden, Icon, IconButton, ImageList, ImageListItem, ImageListItemBar, Input, InputAdornment, InputBase, InputLabel, LinearProgress, Link, List, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemSecondaryAction, ListItemText, ListSubheader, Menu, MenuItem, MenuList, MobileStepper, Modal, NativeSelect, NoSsr, OutlinedInput, Pagination, PaginationItem, Paper, Popover, Popper, Portal, Radio, RadioGroup, Rating, ScopedCssBaseline, Select, Skeleton, Slide, Slider, Snackbar, SnackbarContent, SpeedDial, SpeedDialAction, SpeedDialIcon, Stack, Step, StepButton, StepConnector, StepContent, StepIcon, StepLabel, Stepper, SvgIcon, SwipeableDrawer, Switch, Tab, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TableSortLabel, Tabs, TabScrollButton, TextField, TextareaAutosize, ToggleButton, ToggleButtonGroup, Toolbar, Tooltip, Typography, useMediaQuery, usePagination, useScrollTrigger, Zoom
} from '@mui/material';


import { ReactComponentSample, ReactComponentBootstrapSample } from '../sections/@dashboard/react';


//firebase
import { auth, firestore, firebaseRef } from '../firebaseConfig';


import { Configuration, OpenAIApi } from "openai";

import { useNavigate } from 'react-router-dom';
import { useOutletContext } from "react-router-dom";

import OnboardingDialogMakeQuery from './OnboardingDialogMakeQuery';

const configuration = new Configuration({
  apiKey: "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",
});

const openai = new OpenAIApi(configuration);


const valuesOptions = [
  {
    value: '@mui/material',
    label: '@mui/material',
  },
  {
    value: 'react-bootstrap',
    label: 'react-bootstrap',
  },
];


// ----------------------------------------------------------------------

export default function MakeReactComponentPage({ history }) {

  /////// FREEMIUM
  const navigate = useNavigate();
  const [historyCount] = useOutletContext();
  if (historyCount >= 50) {
    navigate('/dashboard/fremium_over');
  }
  /////// FREEMIUM

  ///// FIREBASE
  const [textInput, setTextInput] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [response, setResponse] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [tables, setTables] = useState([]);
  const [openSnackBar, setOpenSnackBar] = useState(false);

  const [tableOptions, setTableOptions] = useState([]);
  const [selectedTables, setSelectedTables] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);

  const [showOnboardingBanner, setShowOnboardingBanner] = useState(false);

  const [visibleCodeAnswers, setVisibleCodeAnswers] = useState([]);

  const [responses, setResponses] = useState([]);

  // Function to toggle the visibility of an item's description
  const toogleCode = (res) => {
    setVisibleCodeAnswers((prevVisible) =>
      prevVisible.includes(res)
        ? prevVisible.filter((id) => id !== res)
        : [...prevVisible, res]
    );
  };

  useEffect(() => {
    const user = auth.currentUser;
    setCurrentUser(user);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.currentUser]);


  const userTablesRef = firestore
    .collection("users")
    .doc(currentUser?.uid ?? "test")
    .collection("tables");


  const userHistoryRef = firestore
    .collection("users")
    .doc(currentUser?.uid ?? "test")
    .collection("history");

  useEffect(() => {
    // Function to fetch the data from localStorage
    const fetcLocalStorage = async () => {
      try {
        // Wait until the localStorage.getItem resolves
        const isfirstTimeTablesPage = await localStorage.getItem('showOnboardingMakePageQuery');

        if (isfirstTimeTablesPage == null) {
          setTimeout(() => {
            setShowOnboardingBanner(isfirstTimeTablesPage);
          }, 1200);
        }

      } catch (error) {
        console.error('Error fetching data from localStorage:', error);
      }
    };

    fetcLocalStorage();
  }, []); // Empty dependency array to run this effect only once


  const handleTextInputChange = (e) => {
    setTextInput(e.target.value);
  };

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleTablesSelectorChange = (event, values) => {
    setSelectedTables(values);
  };

  const handleQuerySubmit = async (e) => {

    const prompt = `Create a react component using ${selectedOption} with the next description:\n\n`;

    const exampleComponent = `
    <Card>
      {/* Title */}
      <CardContent>
        <Typography variant="h5" component="div">
          TITLE
        </Typography>
      </CardContent>

      {/* Description */}
      <CardContent>
        <Typography variant="body2">
          DESCRIPTION
        </Typography>
      </CardContent>
    </Card>
    `;

    const exampleComponentBootstrap = `<Card style={{ width: '18rem' }}>
    <Card.Body>
      <Card.Title>Card Title</Card.Title>
      <Card.Text>
        Some quick example text to build on the card title and make up the
        bulk of the card's content.
      </Card.Text>
    </Card.Body>
  </Card>`;

    const exampleComponent2 = `
    <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <Card>
                    <CardHeader title="Card Title 1" />
                    <CardContent>
                        <Typography variant="body2" color="text.secondary">
                            Card Description 1
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <Card>
                    <CardHeader title="Card Title 2" />
                    <CardContent>
                        <Typography variant="body2" color="text.secondary">
                            Card Description 2
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <Card>
                    <CardHeader title="Card Title 3" />
                    <CardContent>
                        <Typography variant="body2" color="text.secondary">
                            Card Description 3
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    `;

    const exampleComponent2Bootstrap = `<Container fluid>
    <Row xs={1} sm={2} md={3} lg={4} spacing={2}>
      <Col>
        <Card>
          <Card.Header>Card Title 1</Card.Header>
          <Card.Body>
            <Card.Text>Card Description 1</Card.Text>
          </Card.Body>
        </Card>
      </Col>
      <Col>
        <Card>
          <Card.Header>Card Title 2</Card.Header>
          <Card.Body>
            <Card.Text>Card Description 2</Card.Text>
          </Card.Body>
        </Card>
      </Col>
      <Col>
        <Card>
          <Card.Header>Card Title 3</Card.Header>
          <Card.Body>
            <Card.Text>Card Description 3</Card.Text>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  </Container>`;

    const noReturn = "```jsx";

    // MUI
    var componentsImportsToUse = `import {
      Accordion, AccordionActions, AccordionDetails, AccordionSummary, Alert, AlertTitle, AppBar, Autocomplete, Avatar, AvatarGroup, Backdrop, Badge, BottomNavigation, BottomNavigationAction, Box, Breadcrumbs, Button, ButtonBase, ButtonGroup, Card, CardActionArea, CardActions, CardContent, CardHeader, CardMedia, Checkbox, Chip, CircularProgress, ClickAwayListener, Collapse, Container, CssBaseline, darkScrollbar, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Drawer, Fab, Fade, FilledInput, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Grid, Unstable_Grid2, Grow, Hidden, Icon, IconButton, ImageList, ImageListItem, ImageListItemBar, Input, InputAdornment, InputBase, InputLabel, LinearProgress, Link, List, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemSecondaryAction, ListItemText, ListSubheader, Menu, MenuItem, MenuList, MobileStepper, Modal, NativeSelect, NoSsr, OutlinedInput, Pagination, PaginationItem, Paper, Popover, Popper, Portal, Radio, RadioGroup, Rating, ScopedCssBaseline, Select, Skeleton, Slide, Slider, Snackbar, SnackbarContent, SpeedDial, SpeedDialAction, SpeedDialIcon, Stack, Step, StepButton, StepConnector, StepContent, StepIcon, StepLabel, Stepper, SvgIcon, SwipeableDrawer, Switch, Tab, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TableSortLabel, Tabs, TabScrollButton, TextField, TextareaAutosize, ToggleButton, ToggleButtonGroup, Toolbar, Tooltip, Typography, useMediaQuery, usePagination, useScrollTrigger, Zoom
    } from '@mui/material';`

    const componentsImportBootstrap = `import { Accordion, AccordionContext, AccordionCollapse, AccordionButton, Alert, Anchor, Badge, Breadcrumb, BreadcrumbItem, Button, ButtonGroup, ButtonToolbar, Card, CardImg, CardGroup, Carousel, CarouselItem, CloseButton, Col, Collapse, Dropdown, DropdownButton, Fade, Form, FormControl, FormCheck, FormFloating, FloatingLabel, FormGroup, FormLabel, FormText, FormSelect, Container, Image, Figure, InputGroup, ListGroup, ListGroupItem, Modal, ModalBody, ModalDialog, ModalHeader, ModalFooter, ModalTitle, Nav, Navbar, NavbarBrand, NavDropdown, NavItem, NavLink, Offcanvas, OffcanvasHeader, OffcanvasTitle, OffcanvasBody, Overlay, OverlayTrigger, PageItem, Pagination, Placeholder, PlaceholderButton, Popover, PopoverHeader, PopoverBody, ProgressBar, Ratio, Row, Spinner, SplitButton, SSRProvider, Stack, Tab, TabContainer, TabContent, Table, TabPane, Tabs, ThemeProvider, Toast, ToastBody, ToastHeader, ToastContainer, ToggleButton, ToggleButtonGroup, Tooltip } from 'react-bootstrap'; `

    if (selectedOption === "react-bootstrap") {
      componentsImportsToUse = componentsImportBootstrap;
    }

    // @mui/material
    var fullPrompt = `${prompt}\n${textInput}. Use these components as you need it: ${componentsImportsToUse} \nYou should return the new component in react component format, without any imports. \nKeep all logic in ONE react component only. Keep all style inside jsx. Your response will be parsed programmatically so please only return the react component code with no explanation and DO NOT return the imports for the library components (ex. import React from 'react'), Do not return ${noReturn}.`;
    //var fullPrompt = `${prompt}\n${textInput}. Use these components as you need it: ${componentsImportsToUse} \nYou should return the new component in react component format, without any imports. \nKeep all logic in ONE react component only. Your response will be parsed programmatically so please only return the react component code with no explanation and DO NOT return the imports for the library components (ex. import React from 'react'), Do not return ${noReturn}.`;


    try {
      setSubmitting(true);

      // console.log(`https://us-central1-promp-project.cloudfunctions.net/getReactComponent1?description=${encodeURIComponent(textInput)}&type=${selectedOption}`);
      axios.get(`https://us-central1-promp-project.cloudfunctions.net/getReactComponent1?description=${encodeURIComponent(textInput)}&type=${selectedOption}`)
        .then(res => {
          const data = res.data;

          setResponses([data.answer]);

          setSubmitting(false);

          const newHistoryRecord = {
            promp: textInput,
            selector: selectedOption,
            response: data.answer,
            createdAt: firebaseRef.firestore.FieldValue.serverTimestamp(),
          };

          userHistoryRef.add(newHistoryRecord)
            .then(() => {
              console.log("done");
            })
            .catch((error) => {
              console.log('Error adding table: ', error);
            });


        })

    } catch (error) {
      setSubmitting(false);
      console.log("Error generating response: ", error);
    }


    // try {
    //   setSubmitting(true);

    //   const completion = await openai.createChatCompletion({
    //     model: "gpt-3.5-turbo-16k",
    //     messages: [{ "role": "user", "content": fullPrompt }],
    //   })

    //   const generatedText = completion.data.choices[0]['message']['content'];

    //   console.log(generatedText);

    //   var updatedGeneratedText = generatedText.replace(/^\n+/, '').replace("```jsx", "").replace("```", "");

    //   const regex = /return \(([\s\S]*)\)/;
    //   const match = updatedGeneratedText.match(regex);

    //   if (match && match[1]) {
    //     updatedGeneratedText = match[1].trim();
    //   }

    //   console.log(updatedGeneratedText);

    //   setSubmitting(false);

    //   setResponses([updatedGeneratedText]);

    //   const newHistoryRecord = {
    //     promp: textInput,
    //     selector: selectedOption,
    //     response: generatedText,
    //     createdAt: firebaseRef.firestore.FieldValue.serverTimestamp(),
    //   };

    //   userHistoryRef.add(newHistoryRecord)
    //     .then(() => {
    //       console.log("done");
    //     })
    //     .catch((error) => {
    //       console.log('Error adding table: ', error);
    //     });

    // } catch (error) {
    //   console.log("Error generating response: ", error);
    // }
  };






  const handleQueryContinueSubmit = async (e) => {

    const lastResponse = responses[responses.length - 1];

    //const prompt = `Update this react component: ${lastResponse} using ${selectedOption} with the next description:\n\n`;
    const prompt = `Update react component using ${selectedOption} with the next description:\n\n`;

    const noReturn = "```jsx";

    const fullPrompt = `${prompt}\n\n${textInput}. \n You should return the new component in this kind of format, without any import. \nKeep all logic in ONE react component only. Keep all style inside jsx. Your response will be parsed programmatically so please only return the react component code with no explanation and DO NOT return the imports for the library components (ex. import React from 'react'), Do not return ${noReturn}, just return the component itself updated.`;

    //const fullPrompt = `${prompt}\n\n${textInput}. \n You should return the new component in this kind of format, without any import. \nKeep all logic in ONE react component only. Your response will be parsed programmatically so please only return the react component code with no explanation and DO NOT return the imports for the library components (ex. import React from 'react'), Do not return ${noReturn}, just return the component itself updated.`;


    try {
      setSubmitting(true);

      //console.log(`https://us-central1-promp-project.cloudfunctions.net/getReactComponentContinue?description=${encodeURIComponent(textInput)}&type=${selectedOption}&lastResponse=${lastResponse}`);
                
      axios.get(`https://us-central1-promp-project.cloudfunctions.net/getReactComponentContinue?description=${encodeURIComponent(textInput)}&type=${selectedOption}&lastResponse=${encodeURIComponent(lastResponse)}`)
        .then(res => {
          const data = res.data;

          setResponses([...responses, data.answer]);

          setSubmitting(false);

          const newHistoryRecord = {
            promp: textInput,
            selector: selectedOption,
            response: data.answer,
            createdAt: firebaseRef.firestore.FieldValue.serverTimestamp(),
          };

          userHistoryRef.add(newHistoryRecord)
            .then(() => {
              console.log("done");
            })
            .catch((error) => {
              console.log('Error adding table: ', error);
            });

        }).catch(error => {
          console.log("ERROOOR");
          console.log(error);
        });

    } catch (error) {
      setSubmitting(false);
      console.log("Error generating response: ", error);
    }



    // try {
    //   setSubmitting(true);

    //   const completion = await openai.createChatCompletion({
    //     model: "gpt-3.5-turbo-16k",
    //     messages: [{ "role": "user", "content": fullPrompt }],
    //   })

    //   const generatedText = completion.data.choices[0]['message']['content'];

    //   var updatedGeneratedText = generatedText.replace(/^\n+/, '').replace("```jsx", "").replace("```", "");

    //   const regex = /return \(([\s\S]*)\)/;
    //   const match = updatedGeneratedText.match(regex);

    //   if (match && match[1]) {
    //     updatedGeneratedText = match[1].trim();
    //   }


    //   const regex2 = /=> \(([\s\S]*)\)/;
    //   const match2 = updatedGeneratedText.match(regex2);
    //   if (match2 && match2[1]) {
    //     updatedGeneratedText = match2[1].trim();
    //   }

    //   console.log(updatedGeneratedText);

    //   setSubmitting(false);

    //   setResponses([...responses, updatedGeneratedText]);

    //   const newHistoryRecord = {
    //     promp: textInput,
    //     selector: selectedOption,
    //     response: generatedText,
    //     createdAt: firebaseRef.firestore.FieldValue.serverTimestamp(),
    //   };

    //   userHistoryRef.add(newHistoryRecord)
    //     .then(() => {
    //       console.log("done");
    //     })
    //     .catch((error) => {
    //       console.log('Error adding table: ', error);
    //     });

    // } catch (error) {
    //   console.log("Error generating response: ", error);
    // }
  };

  const handleSnackBarClick = () => {
    setOpenSnackBar(true);
  };

  const handleSnackBarClose = () => {
    setOpenSnackBar(false);
  };

  const getComponentNamesToImport = (text) => {
    if (text == null || text === "") {
      return [];
    }

    const regex = /<([A-Za-z]+)\s*.*?>/g;

    // Array to store the found component names
    const componentNames = [];

    let match;
    while ((match = regex.exec(text))) {
      componentNames.push(match[1]);
    }

    const componentsToRender = [...new Set(componentNames)];


    var whichLibrary = '@mui/material';

    if (selectedOption === "react-bootstrap") {
      whichLibrary = 'react-bootstrap';
    }

    return `import {${componentsToRender.join(", ")}} from '${whichLibrary}';`
  }

  return (
    <>
      <Helmet>
        <title> Dashboard: Make Query | SpeakDB </title>
      </Helmet>

      <Container>
        <Typography variant="h4">
          Create React component
        </Typography>

        <Box sx={{ mb: 5 }}></Box>

        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { m: 1, width: '98%' },
          }}
          noValidate
          autoComplete="off"
        >
          <div>
            <TextField
              id="make-query"
              label="What kind of component would you like to create..."
              multiline
              rows={4}
              onChange={handleTextInputChange}
            />

            <TextField
              id="outlined-select-currency"
              select
              label="Select"
              helperText="Please select framework"
              onChange={handleOptionChange}
            >
              {valuesOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>

            <Button onClick={() => { handleQuerySubmit() }} sx={{ width: '25ch', height: 56, mt: 1, ml: 1 }} variant="contained">
              Create component
            </Button>
          </div>
        </Box>

        {responses.length > 0 && <Typography variant="h5" sx={{ mb: 1, mt: 4 }}>
          Components
        </Typography>}


        {/* <ReactComponentSample text={`
          const GridComponent = () => {
            return (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Card>
                    <CardHeader title="Card Title 1" />
                    <CardContent>
                      <Typography variant="body2" color="text.secondary">
                        Card Description 1
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Card>
                    <CardHeader title="Card Title 2" />
                    <CardContent>
                      <Typography variant="body2" color="text.secondary">
                        Card Description 2
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Card>
                    <CardHeader title="Card Title 3" />
                    <CardContent>
                      <Typography variant="body2" color="text.secondary">
                        Card Description 3
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            );
          };
          
          export default GridComponent;
        `} /> */}

        {responses.map((response_thread) => {
          return <div> {selectedOption === "@mui/material" ? <ReactComponentSample text={response_thread} /> : <ReactComponentBootstrapSample text={response_thread} />}

            {visibleCodeAnswers.includes(response_thread) &&
              <ReactPlaygroundStyled
                defaultValue={`${getComponentNamesToImport(responses[responses.length - 1])}\n\n${response_thread}`}
                playgroundWrapper={
                  Wrapper => styled(Wrapper)`
                  margin-top: 8px;`
                }
                viewerWrapper={
                  Wrapper => styled(Wrapper)`
                  display: none;`
                }

                editorWrapper={
                  Wrapper => styled(Wrapper)``
                }
                scope={{ Accordion, AccordionActions, AccordionDetails, AccordionSummary, Alert, AlertTitle, AppBar, Autocomplete, Avatar, AvatarGroup, Backdrop, Badge, BottomNavigation, BottomNavigationAction, Box, Breadcrumbs, Button, ButtonBase, ButtonGroup, Card, CardActionArea, CardActions, CardContent, CardHeader, CardMedia, Checkbox, Chip, CircularProgress, ClickAwayListener, Collapse, Container, CssBaseline, darkScrollbar, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Drawer, Fab, Fade, FilledInput, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Grid, Unstable_Grid2, Grow, Hidden, Icon, IconButton, ImageList, ImageListItem, ImageListItemBar, Input, InputAdornment, InputBase, InputLabel, LinearProgress, Link, List, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemSecondaryAction, ListItemText, ListSubheader, Menu, MenuItem, MenuList, MobileStepper, Modal, NativeSelect, NoSsr, OutlinedInput, Pagination, PaginationItem, Paper, Popover, Popper, Portal, Radio, RadioGroup, Rating, ScopedCssBaseline, Select, Skeleton, Slide, Slider, Snackbar, SnackbarContent, SpeedDial, SpeedDialAction, SpeedDialIcon, Stack, Step, StepButton, StepConnector, StepContent, StepIcon, StepLabel, Stepper, SvgIcon, SwipeableDrawer, Switch, Tab, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TableSortLabel, Tabs, TabScrollButton, TextField, TextareaAutosize, ToggleButton, ToggleButtonGroup, Toolbar, Tooltip, Typography, useMediaQuery, usePagination, useScrollTrigger, Zoom }}
              />
            }

            <div style={{ marginTop: 12 }}>
              <Button variant="text" onClick={() => {
                navigator.clipboard.writeText(response_thread);
                handleSnackBarClick();
              }}>Copy code</Button>

              <Button style={{ color: "#5DADE2" }} variant="text" onClick={() => {
                toogleCode(response_thread)
              }}>{visibleCodeAnswers.includes(response_thread) ? "Hide code" : "Show Code"}</Button>
            </div>
          </div>
        })}

        {responses.length > 0 && <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { m: 1, width: '98%', mt: 4 },
          }}
          noValidate
          autoComplete="off"
        >

          <TextField
            id="make-query"
            label="keep updating your component here..."
            multiline
            rows={2}
            onChange={handleTextInputChange}
          />

          <Button onClick={() => { handleQueryContinueSubmit() }} sx={{ width: '25ch', height: 56, mt: 1, ml: 1, backgroundColor: "#5DADE2" }} variant="contained">
            Update component
          </Button>
        </Box>}

        {submitting && <Box sx={{ mt: 1, ml: 1 }}>
          <CircularProgress />
        </Box>}

        {/* {responses.length > 0 && <div style={{ marginTop: 24 }}>
          <p>Remember to import this components from library:</p>
          {getComponentNamesToImport(responses[responses.length - 1])}
        </div>} */}
      </Container>

      <Snackbar
        open={openSnackBar}
        onClose={handleSnackBarClose}
        autoHideDuration={3000}
        message="Code copied"
      />
    </>
  );
}
