import * as React from 'react';
import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Container, Stack } from '@mui/material';
import TableList from '../sections/@dashboard/tables/TableList';
import Lottie from "lottie-react";
import * as animationData from './lottieAnimations/groovyWalk.json'

import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(
  props, ref
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function OnboardingDialog({ showOnboarding }) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (showOnboarding == null || showOnboarding === true) {
      handleClickOpen()
    }
  }, [showOnboarding])

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (showOnboarding == null || showOnboarding === true) {
      localStorage.setItem('showOnboardingTablesPage', false);
    }
    setOpen(false);
  };

  return (
    <div>
      <Button variant="text" sx={{ fontSize: 12 }} onClick={handleClickOpen}>
        Navigate like a pro
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Welcome to SpeakDB!
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
              Done
            </Button>
          </Toolbar>
        </AppBar>

        <Container sx={{
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          rowGap: 8,
          mb: 2
        }}>

          <Stack direction="row" alignItems="center" spacing={2}>
            <Lottie animationData={animationData} loop={true} style={{ height: 180 }} />

            <Typography variant="subtitle1">
              Let's get started on creating tables for seamless data exploration with speakDB. Providing context for your database is essential to enable speakDB to generate queries and mutations effectively. Simply follow these steps:
            </Typography>
          </Stack>

          <Box sx={{ display: 'flex', mb: 1 }}>
            <Typography variant="subtitle2" sx={{ mr: 1 }}>
              Name:{""}
            </Typography>

            <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
              Enter the table name for your database. This will ensure that the queries and mutations are accurately linked to the right table.
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', mb: 1, mt: 1 }}>
            <Typography variant="subtitle2" sx={{ mr: 1 }}>
              Description:{""}
            </Typography>

            <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
              Provide a brief overview of the table's data and its relationships with other tables. This will help us understand the purpose and content of the table better.
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', mb: 3, mt: 1 }}>
            <Typography variant="subtitle2" sx={{ mr: 1 }}>
              Fields:{""}
            </Typography>

            <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
              List the field names separated by commas (,). For example: name, description, created_at.
            </Typography>
          </Box>


          <Typography variant="subtitle1" sx={{ mb: 3, mt: 2, color: "#2065D1" }}>
            By following these steps, you'll unlock the full potential of our OpenAI-backed web platform, making data exploration a breeze. Need inspiration? Check out the tables examples below. Let's dive in!
          </Typography>



        </Container>

        <Container sx={{ // color: 'text.secondary'
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          mb: 4
        }}>

          <TableList noEdit={true} tables={[{
            name: 'User',
            description: 'This is the user which is using the platform, user has multiple menus',
            fields: "name, last_name, created_at",
            color: "#AED6F1",
          }, {
            name: 'Menu',
            description: 'A restaurant menu which has categories and each category have items',
            fields: "categories, items, comment, language, name, origin_id, origin_type, stores, user_id",
            color: "#C8A2C8",
          }, {
            name: 'Category',
            description: 'A menu category',
            fields: "items, name , menu_id, description",
            color: "#FFDAB9",
          }, {
            name: 'Item',
            description: 'A restaurant menu item from a category',
            fields: "name , price, description , category_id, menu_id",
            color: "#9696fd",
          },]} />
        </Container>
      </Dialog>
    </div>
  );
}