import * as React from 'react';
import { useState } from 'react';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Chip, CircularProgress } from '@mui/material';


//firebase
import { firebaseRef } from '../../../firebaseConfig';

const testTables = [
  {
    name: "Restaurant",
    description: "A restaurant which has only one menu",
    fieds: "name, description, address"
  },
  {
    name: "Menu",
    description: "A restaurant menu which has categories and each category have items",
    fieds: "categories, items, comment, language, name, restaurant_id"
  },
  {
    name: "Category",
    description: "A menu category",
    fieds: "items, name , menu_id, description"
  },
  {
    name: "Item",
    description: "A restaurant menu item from a category",
    fieds: "name , price, description , category_id, menu_id"
  }
];

const COLORS = [
  "#FFD1DC", // Pale Pink
  "#AED6F1", // Baby Blue
  "#FFDAB9", // Peach
  "#C8A2C8", // Lilac
  "#F3E5AB", // Buttercup Yellow
  "#F08080", // Light Coral
  "#87CEEB", // Sky Blue
  "#98FB98",  // Pale Green
  "#5d9b9b",
  "#a2caea",
  "#9696fd",
  "#96fdc9",
];


export default function BetaTablesBanner({ userTablesRef }) {
  const [loading, setLoading] = useState(false);


  const getRandomColor = () => {
    const randomIndex = Math.floor(Math.random() * COLORS.length);
    return COLORS[randomIndex];
  }

  const handleAddTable = () => {
    setLoading(true);
    testTables.forEach((table) => {
      const newTable = {
        name: table.name,
        description: table.description,
        fields: table.fieds,
        color: getRandomColor(),
        createdAt: firebaseRef.firestore.FieldValue.serverTimestamp(),
      };

      userTablesRef.add(newTable)
        .then(() => {
          window.location.reload();
        })
        .catch((error) => {
          console.log('Error adding tables: ', error);
        });
    })
  };


  return (
    <Card sx={{ widh: '100%', mb: 3 }}>
      <CardContent>
        <Chip label="Beta" color="primary" variant="outlined" />
        <Chip sx={{ ml: 1 }} label="Help" color="warning" variant="outlined" />
        <Typography sx={{ fontSize: 14, mt: 2 }} color="text.primary" gutterBottom>
          We can generate a database with tables specifically designed for testing purposes. Would you be interested in that? You can also update the tables as per your requirements.
        </Typography>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          We have prepared a sample restaurant database for you, including the following tables: Restaurant, Menu, Category, and Item.
        </Typography>

        <Button disabled={loading} onClick={() => {
          handleAddTable()
        }} size="small">Create tables</Button>

        {loading && <CircularProgress sx={{ ml: 2 }} size={20} />}
      </CardContent>
    </Card>
  );
}