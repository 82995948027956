import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';

// @mui
import { Container, FormControlLabel, Typography, Box, TextField, Button, Snackbar, CircularProgress, Checkbox, Stack } from '@mui/material';

import { useNavigate } from 'react-router-dom';
import { useOutletContext } from "react-router-dom";

//firebase
import { auth, firestore, firebaseRef } from '../firebaseConfig';


import { Configuration, OpenAIApi } from "openai";

const configuration = new Configuration({
  apiKey: "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",
});

const openai = new OpenAIApi(configuration);

// ----------------------------------------------------------------------

export default function MakeSpreadsheetsQueryPage() {

  /////// FREEMIUM
  const navigate = useNavigate();
  const [historyCount] = useOutletContext();
  if(historyCount >= 50){
    navigate('/dashboard/fremium_over');
  }
  /////// FREEMIUM

  ///// FIREBASE
  const [textInput, setTextInput] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [currentUser, setCurrentUser] = useState(null);
  const [spreadsheetUrl, setSpreadsheetUrl] = useState('');

  const [fileData, setFileData] = useState(null);
  const [formulaText, setFormulaText] = useState(false);

  const [data, setData] = useState([]);
  const [headers, setHeaders] = useState([]);

  const [checkboxInstructionsSelected, setCheckboxInstructionsSelected] = useState(true);

  useEffect(() => {
    const user = auth.currentUser;
    setCurrentUser(user);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.currentUser]);

  const userHistoryRef = firestore
    .collection("users")
    .doc(currentUser?.uid ?? "test")
    .collection("history");

  const handleTextInputChange = (e) => {
    setTextInput(e.target.value);
  };

  const handleCheckboxChange = (event) => {
    setCheckboxInstructionsSelected(event.target.checked);
  };

  const handleQuerySubmit = async () => {
    setSubmitting(true);

    try {
      var includeExplanation = "Do not inlucde explanation. Return only the formula.";

      if (checkboxInstructionsSelected) {
        includeExplanation = "Return the formulate and the explanation of how to use it.";
      }

      const prompt = `Create a google sheet formulate to ${textInput}.\nThese are the headers:\n${headers}\n\nThere are ${headers.length} columns.\nThis is the structure of the google sheet:\n${data}. \n${includeExplanation}`;

      const completion = await openai.createChatCompletion({
        model: "gpt-3.5-turbo-16k",
        messages: [{ "role": "user", "content": prompt }],
      })

      const generatedText = completion.data.choices[0]['message']['content'];

      setFormulaText(generatedText);

      setSubmitting(false);

      const newHistoryRecord = {
        promp: textInput,
        response: generatedText,
        createdAt: firebaseRef.firestore.FieldValue.serverTimestamp(),
      };

      userHistoryRef.add(newHistoryRecord)
        .then(() => {
          console.log("done");
        })
        .catch((error) => {
          console.log('Error adding table: ', error);
        });
    }
    catch (error) {
      setSubmitting(false);
      console.log("Error: ", error);
    }
  };

  const handleSnackBarClick = () => {
    setOpenSnackBar(true);
  };

  const handleSnackBarClose = () => {
    setOpenSnackBar(false);
  };

  const newFetchData = async () => {
    try {
      setErrorMessage('');
      // Get the spreadsheet ID from the URL
      const urlParts = spreadsheetUrl.split('/');
      const spreadsheetId = urlParts[urlParts.length - 2];

      // Fetch sheet information from Google Sheets API
      const sheetsResponse = await fetch(
        `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}?key=AIzaSyBNsav9vajPuKCGIk-ZAqc_5bfhtc3wfNg`
      );

      if (!sheetsResponse.ok) {
        const errorData = await sheetsResponse.json();

        if (errorData) {
          throw new Error(errorData.error.message);
        }
        else {
          throw new Error('Failed to fetch data from Google Sheets API');
        }

      }

      const sheetsData = await sheetsResponse.json();

      // Get the first sheet in the spreadsheet
      const sheetName = sheetsData.sheets[0].properties.title;

      // Fetch data from Google Sheets API using the sheet name
      const valuesResponse = await fetch(
        `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${sheetName}?key=AIzaSyBNsav9vajPuKCGIk-ZAqc_5bfhtc3wfNg`
      );

      if (!valuesResponse.ok) {
        const errorData = await sheetsResponse.json();

        if (errorData) {
          throw new Error(errorData.error.message);
        }
        else {
          throw new Error('Failed to fetch data from Google Sheets API');
        }
      }

      const responseData = await valuesResponse.json();
      const values = responseData.values;

      setFileData(values);

      if (values.length > 0) {
        setHeaders(values[0]);
        setData(values.slice(1).slice(0, 9));
      } else {
        setHeaders([]);
        setData([]);
      }
    } catch (error) {

      setErrorMessage(`Error: ${error.message}`);
      console.error(error);
    }
  };

  return (
    <>
      <Helmet>
        <title> Dashboard: Make Query | SpeakDB </title>
      </Helmet>

      <Container sx={{ mb: 32 }}>
        <Typography variant="h4" sx={{ mb: 1 }}>
          Create Google Spreadsheet Formula
        </Typography>

        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          * Please ensure that the Google Sheets are set to public access.
        </Typography>

        <Typography variant="body2" sx={{ color: 'text.secondary', mb: 5 }}>
          * Please activate Iterative Calculation for formulas to be executed.
        </Typography>

        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { m: 1, width: '98%' },
          }}
          noValidate
          autoComplete="off"
        >
          <div>

            <TextField
              label="Google Spreadsheet URL"
              value={spreadsheetUrl}
              onChange={(e) => setSpreadsheetUrl(e.target.value)}
            />

            <Button onClick={newFetchData}>Fetch Data</Button>

            {errorMessage !== "" && errorMessage !== null && <Typography variant="body2" sx={{ color: 'text.secondary', mt: 1, ml: 1 }}>
              {errorMessage}
            </Typography>
            }


            {data && data.length > 0 && <h3>Data from Google Sheets:</h3>}
            <div style={{ maxWidth: '100vw', overflowX: 'auto', paddingBottom: 12 }}>
              <table style={{ minWidth: '100%' }}>
                <thead>
                  <tr>
                    {headers.map((header, index) => (
                      <th style={{ width: '80px', padding: '8px', border: '1px solid black' }} key={index}>
                        {header}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {data.map((row, index) => (
                    <tr key={index}>
                      {row.map((cell, cellIndex) => (
                        <td
                          key={`${index}-${cellIndex}`}
                          style={{ width: '80px', padding: '8px', border: '1px solid black' }}
                        >
                          <div style={{ maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                            {cell}
                          </div>
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div style={{ marginTop: 16 }}></div>

            <TextField
              id="make-query"
              label="Explain your formula"
              multiline
              rows={2}
              onChange={handleTextInputChange}
            />

            <Button disabled={data.length === 0} onClick={() => { handleQuerySubmit() }} sx={{ width: '25ch', height: 56, mt: 1, ml: 1 }} variant="contained">
              Create Formula
            </Button>

            <FormControlLabel sx={{ ml: 2 }} control={<Checkbox defaultChecked={checkboxInstructionsSelected}
              onChange={handleCheckboxChange} />} label="Include Instructions" />

            {submitting && <Box sx={{ mt: 1, ml: 1 }}>
              <CircularProgress />
            </Box>}
          </div>
        </Box>

        {formulaText && formulaText !== "" && <div style={{ paddingTop: '28px', marginLeft: 8 }}>
          {/* <code style={{ fontFamily: 'monospace', backgroundColor: '#f1f1f1', padding: 4, whiteSpace: 'pre', fontSize: 16, marginRight: 12 }} className="code">
            {formulaText}
          </code> */}

          <Stack direction="row" alignItems="center" spacing={2}>

            <Box sx={{ minWidth: 240, flexGrow: 1 }}>



              <Typography variant="body2" style={{ maxWidth: '100vw', overflow: 'auto', paddingBottom: 12 }}>
                <code style={{ fontFamily: 'monospace', backgroundColor: '#f1f1f1', padding: 4, whiteSpace: 'pre', fontSize: 16, marginRight: 12 }} className="code">
                  {formulaText}
                </code>
              </Typography>
            </Box>

          </Stack>

          <Button variant="text" onClick={() => {
            navigator.clipboard.writeText(formulaText);
            handleSnackBarClick();
          }}>Copy code</Button>
        </div>}

      </Container>

      <Snackbar
        open={openSnackBar}
        onClose={handleSnackBarClose}
        autoHideDuration={3000}
        message="Code copied"
      />
    </>
  );
}
