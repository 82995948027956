import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';

// @mui
import { Container, Typography, Box, TextField, Button } from '@mui/material';
// components
import { TableList } from '../sections/@dashboard/tables';
import { BetaTablesBanner } from '../sections/@dashboard/tables';

//firebase
import { auth, firestore, firebaseRef } from '../firebaseConfig';

import OnboardingDialog from './OnboardingDialog';

const COLORS = [
  "#FFD1DC", // Pale Pink
  "#AED6F1", // Baby Blue
  "#FFDAB9", // Peach
  "#C8A2C8", // Lilac
  "#F3E5AB", // Buttercup Yellow
  "#F08080", // Light Coral
  "#87CEEB", // Sky Blue
  "#98FB98",  // Pale Green
  "#5d9b9b",
  "#a2caea",
  "#9696fd",
  "#96fdc9",
];

// ----------------------------------------------------------------------

export default function TablesPage() {

  ///// FIREBASE
  const [tableName, setTableName] = useState('');
  const [tableDescription, setTableDescription] = useState('');
  const [tableFields, setTableFields] = useState('');
  const [tables, setTables] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [fetchTablesAgain, setFetchTablesAgain] = useState(false);
  const [showDemoBanner, setShowDemoBanner] = useState(false);

  const [showOnboardingBanner, setShowOnboardingBanner] = useState(false);

  const getRandomColor = () => {
    const randomIndex = Math.floor(Math.random() * COLORS.length);
    return COLORS[randomIndex];
  }

  const handleTableNameChange = (e) => {
    setTableName(e.target.value);
  };

  const handleTableDescriptionChange = (e) => {
    setTableDescription(e.target.value);
  };

  const handleTableFieldsChange = (e) => {
    setTableFields(e.target.value);
  };

  const handleAddTable = () => {
    const newTable = {
      name: tableName,
      description: tableDescription,
      fields: tableFields,
      color: getRandomColor(),
      createdAt: firebaseRef.firestore.FieldValue.serverTimestamp(),
    };

    //console.log(newTable);

    userTablesRef.add(newTable)
      .then(() => {
        setTableName('');
        setTableDescription('');
        setTableFields('');

        setFetchTablesAgain(!fetchTablesAgain);
      })
      .catch((error) => {
        console.log('Error adding table: ', error);
      });
  };

  useEffect(() => {
    const user = auth.currentUser;
    setCurrentUser(user);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.currentUser]);


  const userTablesRef = firestore
    .collection("users")
    .doc(currentUser?.uid ?? "test")
    .collection("tables");


  const fetchUserTables = async () => {

    try {
      const snapshot = await userTablesRef.get();
      const tableData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setTables(tableData);

      setTimeout(() => {
        setShowDemoBanner(true);
      }, 2000);
    } catch (error) {
      setShowDemoBanner(true);
      console.log("Error fetching user tables: ", error);
    }
  };

  useEffect(() => {
    fetchUserTables();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, fetchTablesAgain]);


  useEffect(() => {
    // Function to fetch the data from localStorage
    const fetcLocalStorage = async () => {
      try {
        // Wait until the localStorage.getItem resolves
        const isfirstTimeTablesPage = await localStorage.getItem('showOnboardingTablesPage');

        if (isfirstTimeTablesPage == null) {
          setTimeout(() => {
            setShowOnboardingBanner(isfirstTimeTablesPage);
          }, 1200);
        }

      } catch (error) {
        console.error('Error fetching data from localStorage:', error);
      }
    };

    fetcLocalStorage();
  }, []); // Empty dependency array to run this effect only once

  return (
    <>
      <Helmet>
        <title> Dashboard: Tables | SpeakDB </title>
      </Helmet>

      <Container>

        {showDemoBanner && tables.length === 0 && <BetaTablesBanner userTablesRef={userTablesRef} />}

        <Typography variant="h4">
          Tables
        </Typography>

        <OnboardingDialog showOnboarding={showOnboardingBanner} />

        <Box sx={{ mb: 5 }}></Box>

        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { m: 1, width: '25ch' },
          }}
          noValidate
          autoComplete="off"
        >
          <div>
            <TextField
              required
              value={tableName}
              id="table-name"
              label="Table Name"
              onChange={handleTableNameChange}
            />

            <TextField
              id="table-description"
              label="Table Description"
              value={tableDescription}
              onChange={handleTableDescriptionChange}
            />

            <TextField
              required
              id="table-fields"
              label="Table Fields"
              value={tableFields}
              onChange={handleTableFieldsChange}
            />

            <Button onClick={() => { handleAddTable() }} sx={{ m: 1, width: '25ch', height: 56 }} variant="contained">
              Create Table
            </Button>
          </div>
        </Box>

        <TableList noEdit={false} sx={{ mt: 1 }} tables={tables ?? []} />
      </Container>

    </>
  );
}
