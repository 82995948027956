import React, { useState } from 'react';
import { Button, Typography, Box, CircularProgress, TextField } from '@mui/material';

import Papa from 'papaparse';

import { Configuration, OpenAIApi } from "openai";

const configuration = new Configuration({
  apiKey: "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",
});

const openai = new OpenAIApi(configuration);

const MakeCSVQueryPage = () => {
  const [fileData, setFileData] = useState(null);
  const [headers, setHeaders] = useState([]);
  const [dataRows, setDataRows] = useState([]);
  const [textInput, setTextInput] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [formulaText, setFormulaText] = useState(false);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const contents = e.target.result;
      const parsedData = Papa.parse(contents, { header: true });
      //const csvData = parsedData.data;
      const csvData = parsedData.data.slice(0, 5); // Get the first 5 rows of data


      setFileData(contents);
      setHeaders(parsedData.meta.fields);
      setDataRows(csvData);

      console.log(csvData);
    };

    reader.readAsText(file);
  };

  const handleChat = async () => {
    setSubmitting(true);
    // Filter the data using ChatGPT
    //const prompt = `Give me all records where the City value is "Mountain View".\n\nCSV Data:\n${fileData}`;


    // console.log(fileData);
    // var separateLines = fileData.split(/\r?\n|\r|\n/g);
    // console.log(separateLines);

    // const values = separateLines.map((row) =>  `\"${row}\"`)

    // const prompt = `Given the next CSV data give me all records where the Current City is San Francisco.\n\n Headers:\n ${values}\nCSV Data:\n${values}. Response as a csv format`;


    const prompt = `Give me all records where City is Palo Alto.\n\nCSV Data:\n${fileData}`;

    console.log(prompt);

    const completion = await openai.createChatCompletion({
      model: "gpt-3.5-turbo-16k",
      messages: [{ "role": "user", "content": prompt }],
    })


    const generatedText = completion.data.choices[0]['message']['content'];

    console.log(generatedText);

    const filteredRecords = Papa.parse(generatedText, { header: true }).data;

    setSubmitting(false);

    console.log(filteredRecords);

    // Update the filtered data state
    setFilteredData(filteredRecords);
  };



  const handleChat2 = async () => {
    setSubmitting(true);

    const includeExplanation = "Return the formulate and the explanation of how to use it.";

    const noExplanation = "Do not inlucde explanation. Return only the formula.";

    const prompt = `Create a google sheet formulate to ${textInput} this is the structure of the google sheet:\n${fileData}. \n${noExplanation}`;

    console.log(prompt);

    const completion = await openai.createChatCompletion({
      model: "gpt-3.5-turbo-16k",
      messages: [{ "role": "user", "content": prompt }],
    })

    const generatedText = completion.data.choices[0]['message']['content'];

    console.log(generatedText);

    setFormulaText(generatedText);

    setSubmitting(false);
  };

  const handleTextInputChange = (e) => {
    setTextInput(e.target.value);
  };


  return (
    <div style={{ paddingBottom: 70 }}>
      <TextField
        id="make-query"
        label="Explain your query"
        multiline
        rows={2}
        onChange={handleTextInputChange}
      />

      <Button variant="contained" onClick={handleChat2}>
        Google formula
      </Button>

      <input type="file" accept=".csv" onChange={handleFileUpload} />
      {fileData && (
        <div style={{ marginTop: 18 }}>
          {/* <Typography variant="h6">Headers:</Typography>
          <ul>
            {headers.map((header, index) => (
              <li key={index}>{header}</li>
            ))}
          </ul> */}
          <Typography variant="h6">Data Rows:</Typography>
          <table>
            <thead>
              <tr>
                {headers.map((header, index) => (
                  <th style={{ width: '80px', padding: '8px', border: '1px solid black' }} key={index}>
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {dataRows.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {headers.map((header, cellIndex) => (
                    <td
                      key={`${rowIndex}-${cellIndex}`}
                      style={{ width: '80px', padding: '8px', border: '1px solid black' }}
                    >
                      <div style={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                        {row[header]}
                      </div>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}


      <Button variant="contained" onClick={handleChat}  sx={{ mt: 4, ml: 1 }}>
        Query/Filter values using chatGPT
      </Button>

      {submitting && <Box sx={{ mt: 1, ml: 1 }}>
        <CircularProgress />
      </Box>}

      {/* Display the filtered data */}
      {filteredData.length > 0 && (
        <div>
          <Typography variant="h6">Filtered Records:</Typography>
          <table>
            <thead>
              <tr>
                {headers.map((header, index) => (
                  <th style={{ width: '80px', padding: '8px', border: '1px solid black' }} key={index}>
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {filteredData.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {headers.map((header, cellIndex) => (
                    <td
                      key={`${rowIndex}-${cellIndex}`}
                      style={{ width: '80px', padding: '8px', border: '1px solid black' }}
                    >
                      <div style={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                        {row[header]}
                      </div>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}


      {formulaText && formulaText !== "" && <div style={{ paddingTop: '28px', marginLeft: 8 }}>
        <code style={{ fontFamily: 'monospace', backgroundColor: '#f1f1f1', padding: 4, whiteSpace: 'pre', fontSize: 16, marginRight: 12 }} className="code">
          {formulaText}
        </code>
      </div>}


    </div>
  );
};

export default MakeCSVQueryPage;