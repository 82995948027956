import React from 'react';
import { Container,Toolbar,AppBar ,Typography, Button, Grid, Card, CardContent, CardMedia } from '@mui/material';

export default function LandingPage() {
    return (
        <div>
          <AppBar position="static">
            <Toolbar>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                Speak DB
              </Typography>
              <Button color="inherit">Button 1</Button>
              <Button color="inherit">Button 2</Button>
            </Toolbar>
          </AppBar>
          <Container maxWidth="md" sx={{ mt: 10 }}>
            <Grid container spacing={4} alignItems="center" justifyContent="center">
              <Grid item xs={12} sm={6}>
                <Typography variant="h2" sx={{ mb: 2 }}>
                  Welcome to SpeakDB
                </Typography>
                <Typography variant="h5" color="textSecondary" sx={{ mb: 4 }}>
                  The ultimate software-as-a-service solution for your business.
                </Typography>
                <Button variant="contained" color="primary" size="large">
                  Get Started
                </Button>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Card sx={{ maxWidth: 400 }}>
                  <CardMedia
                    component="img"
                    height="250"
                    image="./speakDBimage.png"
                    alt="SpeakDB Screenshot"
                  />
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      Powerful Features
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut dapibus finibus est, non vehicula erat
                      consequat in. Fusce suscipit turpis in lorem dignissim, sed dapibus tortor cursus.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Container>
        </div>
      );
}