// src/firebaseConfig.js
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';

const firebaseConfig = {
  apiKey: "AIzaSyDxe2I3WWgTO2sbg1WyeS9Z0Rq7eXxdDtE",
  authDomain: "promp-project.firebaseapp.com",
  projectId: "promp-project",
  storageBucket: "promp-project.appspot.com",
  messagingSenderId: "729012994351",
  appId: "1:729012994351:web:ed00260b576719dd6fa1da",
  measurementId: "G-ZQJEH85JY5",
};

firebase.initializeApp(firebaseConfig);
export const firestore = firebase.firestore();
export const auth = firebase.auth();
export const firebaseRef = firebase;
