import * as React from 'react';
import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Container, Stack } from '@mui/material';
import Lottie from "lottie-react";
import * as animationData from './lottieAnimations/duckWalk.json'

import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(
  props, ref
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function OnboardingDialogMakeQuery({ showOnboarding }) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (showOnboarding == null || showOnboarding === true) {
      handleClickOpen()
    }
  }, [showOnboarding])

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (showOnboarding == null || showOnboarding === true) {
      localStorage.setItem('showOnboardingMakePageQuery', false);
    }
    setOpen(false);
  };

  return (
    <div>
      <Button variant="text" sx={{ fontSize: 12 }} onClick={handleClickOpen}>
        Navigate like a pro
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Crafting Amazing Queries: A How-To Guide
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
              Done
            </Button>
          </Toolbar>
        </AppBar>

        <Container sx={{
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          rowGap: 8,
          mb: 2
        }}>

          {/* <Typography variant="h4" sx={{ mb: 2, mt: 4 }}>
            How to create tables?
          </Typography> */}

          <Lottie animationData={animationData} loop={true} style={{ height: 200 }} />

          <Typography variant="subtitle1" sx={{ mt: 2 }}>
            Let's create the best possible queries together! To get you started, here are some inspiring examples:
          </Typography>

          <Box sx={{ display: 'flex', mb: 1, mt: 2 }}>
            <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
              * Give me All item from all categories from a mneu with id: "1234"
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', mb: 1, mt: 1 }}>
            <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
              * Get all users with first start start with "Arturo", age is more than 25 and created_at is more than May 29 2023
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', mb: 3, mt: 1 }}>
            <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
              * Get items with price is less than 4 and name start with "Dessert"
            </Typography>
          </Box>


          <Typography variant="subtitle1" sx={{ mb: 1, mt: 4 }}>
            Outputs:
          </Typography>
        </Container>

        <Container sx={{
        }}>

          <div style={{ paddingTop: '4px' }}>
            <code style={{ fontFamily: 'monospace', backgroundColor: '#f1f1f1', padding: 4, whiteSpace: 'pre', fontSize: 16, marginRight: 12 }} className="code">
              {"SELECT * FROM Item WHERE menu_id = 1234"}
            </code>
          </div>

          <div style={{ paddingTop: '16px' }}>
            <code style={{ fontFamily: 'monospace', backgroundColor: '#f1f1f1', padding: 4, whiteSpace: 'pre', fontSize: 16, marginRight: 12 }} className="code">
              {"SELECT * FROM users  WHERE first_name LIKE 'Arturo%' AND age > 25 AND created_at > '2023-05-29';"}
            </code>
          </div>

          <div style={{ paddingTop: '16px' }}>
            <code style={{ fontFamily: 'monospace', backgroundColor: '#f1f1f1', padding: 4, whiteSpace: 'pre', fontSize: 16, marginRight: 12 }} className="code">
              {"DB::table('Item')->where('price', '<', 4)->where('name', 'like', 'Dessert%')->get();"}
            </code>
          </div>

        </Container>
      </Dialog>
    </div>
  );
}