import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box } from '@mui/material';

// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '70vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
}));

// ----------------------------------------------------------------------

export default function FreeAccountOverPage() {
  return (
    <>
      <Helmet>
        <title> Freemium | Speak DB </title>
      </Helmet>

      <Container>
        <StyledContent sx={{ textAlign: 'center', alignItems: 'center' }}>
          <Typography variant="h4" paragraph>
            Thanks for testing the beta version of <Typography variant="h4" gutterBottom style={{ color: '#2065D1' }}>
              SpeakDB
            </Typography>
          </Typography>

          <Typography sx={{ color: 'text.secondary' }}>
            You are out of tokens.
            Your feedback has been invaluable in shaping our final version. We appreciate your support and look forward to delivering an exceptional web app experience.
          </Typography>

          <Box
            component="img"
            src="/assets/illustrations/thankyou2.jpg"
            sx={{ height: 260, mx: 'auto', my: { xs: 5 }, borderRadius: 8 }}
          />
        </StyledContent>
      </Container>
    </>
  );
}
