import React, { useState } from 'react';

import { Button, Modal, Box, Stack, Typography } from '@mui/material';

import { firestore, auth } from '../../../firebaseConfig';

const ModalTableUpdate = ({ tableData, open, onClose, onSubmit }) => {
    const user = auth.currentUser;


    const handleTableDelete = async () => {
        if (!user?.uid) {
            return;
        }

        try {
            const tableRef = firestore.collection('users').doc(user.uid).collection('tables').doc(tableData.id);

            await tableRef.delete();

            console.log('Table deleted successfully!');
            onClose();
            window.location.reload();
        } catch (error) {
            console.log('Error updating table: ', error);
        }
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 4,
                }}
            >
                <Stack sx={{ paddingTop: 2 }} direction="row" alignItems="center" justifyContent="space-between">
                    <Typography variant="subtitle2">
                        Are you sure you want to delete this table ?
                    </Typography>
                </Stack>

                <Stack direction={"row"} spacing={2} sx={{ p: 2 }}>
                    <Button sx={{ flex: 1 }} variant="text" onClick={() => { handleTableDelete() }}>Yes </Button>
                    <Button sx={{ flex: 1 }} variant="text" onClick={() => { onClose() }}>No </Button>
                </Stack>
            </Box>
        </Modal>
    );
};

export default ModalTableUpdate;