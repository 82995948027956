// src/MakeQuery.js
import React, { useState, useEffect } from "react";
import { auth, firestore } from "./firebaseConfig";
import { Configuration, OpenAIApi } from "openai";

const configuration = new Configuration({
  apiKey: "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",
});

const openai = new OpenAIApi(configuration);

const MakeQuery = () => {
  const [userTables, setUserTables] = useState([]);
  const [textInput, setTextInput] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [response, setResponse] = useState("");
  const [currentUser, setCurrentUser] = useState(null);



  useEffect(() => {
    const user = auth.currentUser;
    setCurrentUser(user);
  }, [auth.currentUser]);


  const userTablesRef = firestore
    .collection("users")
    .doc(currentUser?.uid ?? "test")
    .collection("tables");


  const fetchUserTables = async () => {
    try {
      const snapshot = await userTablesRef.get();
      const tableData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setUserTables(tableData);
    } catch (error) {
      console.log("Error fetching user tables: ", error);
    }
  };

  useEffect(() => {
    fetchUserTables();
  }, [currentUser]);

  const handleTextInputChange = (e) => {
    setTextInput(e.target.value);
  };

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleQuerySubmit = async (e) => {
    e.preventDefault();

    const prompt = `I have the following database structure:\n\n`;
    const tableStructure = userTables
      .map(
        (table) =>
          `Table name: ${table.name}\nTable Description: ${table.description}\nTable fields: ${table.fields}\n`
      )
      .join("\n");

    const fullPrompt = `${prompt}${tableStructure}\n Create code to query:\n\n${textInput} , return the code in a ${selectedOption} structure. Your response will be parsed programmatically so please only return the code.`;

    try {
      const response = await openai.createCompletion({
        model: "text-davinci-003", // Replace with the desired GPT-3 model
        prompt: fullPrompt,
        max_tokens: 500,
        temperature: 0.7,
        n: 1,
      });

      //const generatedText = response.data.choices[0].text.trim();
      const generatedText = response.data.choices[0].text;

      const updatedGeneratedText = generatedText.replace(/^\n+/, '');

      setResponse(updatedGeneratedText);
    } catch (error) {
      console.log("Error generating response: ", error);
    }

    setTextInput("");
  };

  return (
    <div style={{ paddingLeft: 12 }}>
      <h2>Make a Query</h2>

      <form onSubmit={handleQuerySubmit}>
        <label>
          Select Database:
          <select value={selectedOption} onChange={handleOptionChange}>
            <option value="">Select</option>
            <option value="PostgreSQL">PostgreSQL</option>
            <option value="MySQL">MySQL</option>
            <option value="Rails console">Rails console</option>
          </select>
        </label>
        <br />
        <label>
          Enter Text:
          <input
            type="text"
            value={textInput}
            onChange={handleTextInputChange}
          />
        </label>
        <br />
        <button type="submit">Submit</button>
      </form>

      <h3>User Tables</h3>
      {userTables.map((table) => (
        <div key={table.id}>
          <h4>{table.name}</h4>
          <p>Description: {table.description}</p>
          <p>Fields: {table.fields}</p>
        </div>
      ))}

      {response && (
        <div>
          <h3>ChatGPT Response</h3>
          <p style={{ whiteSpace: 'pre-line' }}>{response}</p>
        </div>
      )}
    </div>
  );
};

export default MakeQuery;
