import { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';

import { useNavigate } from 'react-router-dom';

//
import Header from './header';
import Nav from './nav';

import { auth, firestore } from "./../../firebaseConfig";

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);
  const [historyCount, setHistoryCount] = useState(0);
  const [currentUser, setCurrentUser] = useState({ username: "loading...", email: "loading..." })

  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        const fetchData = async () => {
          const userDoc = await firestore.collection('users').doc(user.uid).get();
          
          setCurrentUser({...userDoc.data(), uid: user.uid});
        };
        fetchData();
      }
      else {
        navigate('/new_login');
      }
    });

    return () => {
      unsubscribe();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const userHistoryRef = firestore
    .collection("users")
    .doc(currentUser?.uid ?? "test")
    .collection("history").orderBy("createdAt", "desc").limit(50);


  const fetchUserHistory = async () => {
    try {
      const snapshot = await userHistoryRef.get();
      const historyData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setHistoryCount(historyData?.length ?? 50);
    } catch (error) {
      console.log("Error fetching user tables: ", error);
    }
  };

  useEffect(() => {
    fetchUserHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);



  return (
    <StyledRoot>
      <Header history={historyCount} user={currentUser} onOpenNav={() => setOpen(true)} />

      <Nav user={currentUser} openNav={open} onCloseNav={() => setOpen(false)} />

      <Main>
        <Outlet context={[historyCount]}/>
      </Main>
    </StyledRoot>
  );
}
